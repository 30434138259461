/* GroupsSelect.css */

.react-select__control {
  border-color: #00bcd4;
}

.react-select__control--is-focused {
  border-color: #00acc1;
}

.react-select__menu {
  background: white;
}

.react-select__option {
  padding: 10px;
}

.react-select__option--is-selected {
  background: #ccfff6;
}

.react-select__option--is-focused {
  background: #f5f5f5;
}

.react-select__multi-value {
  background: #e1fcff;
}

.react-select__multi-value__label {
  color: #00796b;
}

.react-select__multi-value__remove {
  color: #00796b;
}

.select-all-option {
  display: flex;
  align-items: center;
  padding: 6px;
  padding-right: 40px;
  margin-top: 1px;
  background-color: white;
}

.select-all-option input {
  margin: 0;
}

.select-all-option label {
  font-size: 14px;
}
