/* Add this to your global CSS or a dedicated CSS module */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

#root {
  height: 100%;
}

.flex {
  display: flex;
}

.h-screen {
  height: 100vh;
}

.flex-1 {
  flex: 1;
}

.p-6 {
  padding: 1.5rem;
}

.bg-gray-100 {
  background-color: #f7fafc;
}

@media print {
  body * {
    visibility: hidden;
  }
  #printable-report, #printable-report * {
    visibility: visible;
  }
  #printable-report {
    position: absolute;
    left: 0;
    top: 0;
  }
}


@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.loader {
  display: inline-block;
  width: 80px;
  height: 80px;
  border: 8px solid #e0e0e0;
  border-radius: 50%;
  border-top-color: #3490dc;
  animation: spin 1s linear infinite, fadeInOut 2s infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.scrollbar-light {
  scrollbar-width: thin;
  scrollbar-color: #ccc #f1f1f1;
}

.scrollbar-light::-webkit-scrollbar {
  width: 8px;
}

.scrollbar-light::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

.scrollbar-light::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.scrollbar-dark {
  scrollbar-width: thin;
  scrollbar-color: #555 #333;
}

.scrollbar-dark::-webkit-scrollbar {
  width: 8px;
}

.scrollbar-dark::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 4px;
}

.scrollbar-dark::-webkit-scrollbar-track {
  background-color: #333;
}


/* home */
.scrollbar-lightrr::-webkit-scrollbar {
  width: 12px;
}

.scrollbar-lightrr::-webkit-scrollbar-track {
  background: hwb(0 100% 0%); /* Tailwind gray-800 */
}
.scrollbar-lightrr::-webkit-scrollbar-thumb {
  background-color: #314d80; /* Tailwind gray-600 */
  border-radius: 10px;
  border: 3px solid #fdfdfd; /* Tailwind gray-800 */
}
.scrollbar-lightrr::-webkit-scrollbar-thumb:hover {
  background-color: #35548d; /* Tailwind gray-700 */
}

.mycolor{
  background-color: #014fa8; /* Tailwind gray-600 */
}


/* firwok styles */

@keyframes firework-explosion {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* real-time-notifications */

.real-notification {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #333;
  color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

/* end notifcations */

/* hide scroll */



.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.hide-scrollbar {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

/*  */
.firework-message {
  position: relative;
  display: inline-block;
  padding: 10px;
  border-radius: 4px;
  color: white;
  background-color: #3fb67c; /* Success color */
  animation: firework-explosion 3s ease-out forwards;
}

.firework-message::before,
.firework-message::after,
.firework-message .dot {
  content: '';
  position: absolute;
  border-radius: 50%;
  background-color: inherit;
  animation: firework-explosion 3s ease-out forwards;
}

.firework-message::before {
  width: 8px;
  height: 8px;
  top: 10px;
  left: 10px;
  animation-delay: 3s;
}

.firework-message::after {
  width: 6px;
  height: 6px;
  bottom: 10px;
  right: 10px;
  animation-delay: 3s;
}

.firework-message .dot {
  width: 5px;
  height: 5px;
  background-color: inherit;
}

.firework-message .dot:nth-child(1) {
  top: 20px;
  left: 20px;
  animation-delay: 3s;
}

.firework-message .dot:nth-child(2) {
  top: 30px;
  right: 30px;
  animation-delay: 3s;
}

.firework-message .dot:nth-child(3) {
  bottom: 20px;
  left: 30px;
  animation-delay: 3s;
}

.firework-message .dot:nth-child(4) {
  bottom: 10px;
  right: 20px;
  animation-delay: 3s;
}

.error-message {
  background-color: #f26358; /* Error color */
}

/* input type range */

input[type='range'] {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  background: #79ffae;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.3s;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #4caf50;
  cursor: pointer;
}

input[type='range']::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #4caf50;
  cursor: pointer;
}


/* rich text editor */

.rich-text-editor .toolbar {
  display: flex;
  gap: 10px;
}

.rich-text-editor .toolbar-btn {
  border: 1px solid #d1d5db; /* Tailwind's gray-300 */
  padding: 5px;
  cursor: pointer;
}

.emoji-mart {
  position: absolute;
  z-index: 10;
}

.custom-input-date::-webkit-calendar-picker-indicator {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
}


[x-cloak] {
  display: none !important;
}

.viewTable select,
.viewTable input{
    /* border: 1px solid #64748B; */
}